import React from 'react';
import {ReferenceField, Filter, TextField, ReferenceInput, AutocompleteInput, BooleanField} from 'react-admin';
import {
    ListGuesser  ,
    FieldGuesser  ,
    CreateGuesser  ,
    InputGuesser  ,
    EditGuesser  ,
} from '@api-platform/admin';



//there is an option alwaysOn for ReferenceInput
export const HomeTasksFilter = (props) => (
    <Filter {...props}>
        <ReferenceInput label="Property" source="property.id" reference="properties" allowEmpty>
            <AutocompleteInput optionText="name" />
        </ReferenceInput>
    </Filter>
);

export const HomeTasksList = props => (
    <div>
        <ListGuesser {...props} filters={<HomeTasksFilter/>}>
            <ReferenceField label="Property" source="property" reference="properties">
                <TextField source="name"/>
            </ReferenceField>
            <FieldGuesser source="name" />
            <FieldGuesser source="points" />
            <FieldGuesser source="recurrency" />
            <FieldGuesser source="status"/>
        </ListGuesser>
    </div>

);

export const HomeTaskCreate = (props) => (
    <CreateGuesser {...props}>
        <ReferenceInput
            source="property"
            reference="properties"
            label="Property"
            filterToQuery={searchText => ({ name: searchText })}
        >
            <AutocompleteInput optionText="name" />
        </ReferenceInput>
        <InputGuesser source="name" />
        <InputGuesser source="points" />
        <InputGuesser source="recurrency" />
        <InputGuesser source="status" />

    </CreateGuesser>
);

export const HomeTaskEdit = props => (
    <EditGuesser {...props}>
        <ReferenceInput
            source="property"
            reference="properties"
            label="Property"
            filterToQuery={searchText => ({ name: searchText })}
        >
            <AutocompleteInput optionText="name" />
        </ReferenceInput>
        <InputGuesser source="name" />
        <InputGuesser source="points" />
        <InputGuesser source="recurrency" />
        <InputGuesser source="status" />

    </EditGuesser>
);
