import React from 'react';
import {FileInput, FileField} from 'react-admin';
import {
    ListGuesser  ,
    FieldGuesser  ,
    CreateGuesser  ,
    InputGuesser  ,
    EditGuesser  ,
} from '@api-platform/admin';

export const MediaObjectsCreate = (props) => (
    <CreateGuesser {...props}>
        <FileInput source="file">
            <FileField source="src" title="title" />
        </FileInput>
    </CreateGuesser>
);

export const MediaObjectsList = props => (
    <div>
        <ListGuesser {...props}>
            <FieldGuesser source="name" />
            <FieldGuesser source="contentUrl" />
        </ListGuesser>
    </div>
)