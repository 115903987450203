import React from 'react';

import { ReferenceField, TextField, ReferenceInput, AutocompleteInput } from 'react-admin';

import {
    ListGuesser  ,
    FieldGuesser  ,
    CreateGuesser  ,
    InputGuesser  ,
    EditGuesser  ,
} from '@api-platform/admin';

const types = [
    { name: "coche", cname: "car", value: "car" },
    { name: "moto", cname: 'motorbike', value: "motorbike" },
    { name: "bici", cname: 'bicicle', value: "bicicle" },
    { name: "ciclomotor", cname: 'ciclomotor', value: "ciclomotor" },
];

export const WorkshopList = props => (
    <div>
        <ListGuesser {...props}>
            <FieldGuesser source="name" />
            <FieldGuesser source="address" />
            <FieldGuesser source="phone" />
            <FieldGuesser source="contactName" />
        </ListGuesser>
    </div>
);

export const WorkshopCreate = (props) => (
    <CreateGuesser {...props}>
        <InputGuesser source="name" />
        <InputGuesser source="address" />
        <InputGuesser source="phone" />
        <InputGuesser source="contactName" />
    </CreateGuesser>
);

export const WorkshopEdit = (props) => (
    <EditGuesser {...props}>
        <InputGuesser source="name" />
        <InputGuesser source="address" />
        <InputGuesser source="phone" />
        <InputGuesser source="contactName" />
    </EditGuesser>
);