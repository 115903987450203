import React from 'react';
import {ReferenceField, Filter, TextField, ReferenceInput, AutocompleteInput} from 'react-admin';
import {
    ListGuesser  ,
    FieldGuesser  ,
    CreateGuesser  ,
    InputGuesser  ,
    EditGuesser  ,
} from '@api-platform/admin';

//there is an option alwaysOn for ReferenceInput
export const VarietiesFilter = (props) => (
    <Filter {...props}>
        <ReferenceInput label="Property" source="property" reference="properties" allowEmpty>
            <AutocompleteInput optionText="name" />
        </ReferenceInput>
    </Filter>
);

export const VarietiesList = props => (
    <div>
        <ListGuesser {...props} filters={<VarietiesFilter/>}>
            <FieldGuesser source="name" />
            <ReferenceField label="Property" source="property" reference="properties">
                <TextField source="name"/>
            </ReferenceField>
            <FieldGuesser source="fanecaes" />
        </ListGuesser>
    </div>

);

export const VarietiesCreate = (props) => (
    <CreateGuesser {...props}>
        <InputGuesser source="name" />
        <InputGuesser source="name_from_page" />
        <ReferenceInput
            source="property"
            reference="properties"
            label="Property"
            filterToQuery={searchText => ({ name: searchText })}
        >
            <AutocompleteInput optionText="name" />
        </ReferenceInput>
        <InputGuesser source="fanecaes" />
    </CreateGuesser>
);

export const VarietiesEdit = props => (
    <EditGuesser {...props}>
        <InputGuesser source="name" />
        <InputGuesser source="name_from_page" />
        <ReferenceInput
            source="property"
            reference="properties"
            label="Property"
            filterToQuery={searchText => ({ name: searchText })}
        >
            <AutocompleteInput optionText="name" />
        </ReferenceInput>
        <InputGuesser source="fanecaes" />

    </EditGuesser>
);
