import React from 'react';

import { ReferenceField, TextField, ReferenceInput, AutocompleteInput, Filter, List , DateInput} from 'react-admin';

import {
    ListGuesser  ,
    FieldGuesser  ,
    CreateGuesser  ,
    InputGuesser  ,
    EditGuesser  ,
} from '@api-platform/admin';
export const TasksFilter = (props) => (
    <Filter {...props}>
        <ReferenceInput label="Vehicle" source="vehicle.id" reference="vehicles" allowEmpty>
            <AutocompleteInput optionText="brand" />
        </ReferenceInput>
        <ReferenceInput label="Workshop" source="workshop.id" reference="workshops" allowEmpty>
            <AutocompleteInput optionText="name" />
        </ReferenceInput>
    </Filter>
)
export const VehicleTasksList = props => (
    <div>
        <ListGuesser {...props} filters={<TasksFilter/>}>
            <ReferenceField label="Vehicle" source="vehicle" reference="vehicles">
                <TextField source="brand"/>
            </ReferenceField>
            <FieldGuesser source="description" />
            <FieldGuesser source="price" />
            <FieldGuesser source="mileage" />
            <ReferenceField label="Taller" source="workshop" reference="workshops">
                <TextField source="name"/>
            </ReferenceField>
            <FieldGuesser source="date" />
        </ListGuesser>
    </div>
);

const Aside = () => (
    <div style={{ width: 200, margin: '1em' }}>
        <p>Hola</p>
    </div>
);

export const VehicleTasksCreate = (props) => (
    <CreateGuesser {...props}>
        <ReferenceInput
            source="vehicle"
            reference="vehicles"
            label="Vehicle"
            filterToQuery={searchText => ({ brand: searchText })}
        >
            <AutocompleteInput optionText="brand" />
        </ReferenceInput>
        <InputGuesser source="description" />
        <InputGuesser source="price" />
        <InputGuesser source="mileage" />
        <ReferenceInput
            source="workshop"
            reference="workshops"
            label="Taller"
            filterToQuery={searchText => ({ name: searchText })}
        >
            <AutocompleteInput optionText="name" />
        </ReferenceInput>
        <DateInput source="date" />
    </CreateGuesser>
);

export const VehicleTasksEdit = (props) => (
    <EditGuesser {...props}>
        <ReferenceInput
            source="vehicle"
            reference="vehicles"
            label="Vehicle"
            filterToQuery={searchText => ({ brand: searchText })}
        >
            <AutocompleteInput optionText="brand" />
        </ReferenceInput>
        <InputGuesser source="description" />
        <InputGuesser source="price" />
        <InputGuesser source="mileage" />
        <ReferenceInput
            source="workshop"
            reference="workshops"
            label="Taller"
            filterToQuery={searchText => ({ name: searchText })}
        >
            <AutocompleteInput optionText="name" />
        </ReferenceInput>
        <DateInput source="date" />
    </EditGuesser>
);