import React from 'react';
import {ReferenceField, Filter, TextField, ReferenceInput, AutocompleteInput, BooleanField} from 'react-admin';
import {
    ListGuesser  ,
    FieldGuesser  ,
    CreateGuesser  ,
    InputGuesser  ,
    EditGuesser  ,
} from '@api-platform/admin';

//there is an option alwaysOn for ReferenceInput
export const HomeTaskHistoryFilter = (props) => (
    <Filter {...props}>
        <ReferenceInput label="User" source="user.id" reference="users" allowEmpty>
            <AutocompleteInput optionText="username" />
        </ReferenceInput>
    </Filter>
);

export const HomeTaskHistoryList = props => (
    <div>
        <ListGuesser {...props} filters={<HomeTaskHistoryFilter/>}>
            <ReferenceField label="User" source="user" reference="users">
                <TextField source="username"/>
            </ReferenceField>
            <ReferenceField label="Task" source="task" reference="home_tasks">
                <TextField source="name"/>
            </ReferenceField>
        </ListGuesser>
    </div>

);

export const HomeTaskHistoryCreate = (props) => (
    <CreateGuesser {...props}>
        <ReferenceInput
            source="user"
            reference="users"
            label="User"
            filterToQuery={searchText => ({ name: searchText })}
        >
            <AutocompleteInput optionText="username" />
        </ReferenceInput>
        <ReferenceInput
            source="task"
            reference="home_tasks"
            label="Task"
            filterToQuery={searchText => ({ name: searchText })}
        >
            <AutocompleteInput optionText="name" />
        </ReferenceInput>
    </CreateGuesser>
);

export const HomeTaskHistoryEdit = props => (
    <EditGuesser {...props}>
        <ReferenceInput
            source="user"
            reference="users"
            label="User"
            filterToQuery={searchText => ({ name: searchText })}
        >
            <AutocompleteInput optionText="username" />
        </ReferenceInput>
        <ReferenceInput
            source="task"
            reference="home_tasks"
            label="Task"
            filterToQuery={searchText => ({ name: searchText })}
        >
            <AutocompleteInput optionText="name" />
        </ReferenceInput>

    </EditGuesser>
);
