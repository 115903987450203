import React from 'react';
import {ReferenceField, Filter, TextField, ReferenceInput, AutocompleteInput} from 'react-admin';
import {
    ListGuesser  ,
    FieldGuesser  ,
    CreateGuesser  ,
    InputGuesser  ,
    EditGuesser  ,
} from '@api-platform/admin';

export const CommercesList = props => (
    <div>
        <ListGuesser {...props}>
            <FieldGuesser source="name" />
            <FieldGuesser source="cif" />
            <FieldGuesser source="contact_person" />
            <FieldGuesser source="phone" />
            <FieldGuesser source="email" />
        </ListGuesser>
    </div>

);

export const CommercesCreate = (props) => (
    <CreateGuesser {...props}>
        <InputGuesser source="name" />
        <InputGuesser source="cif" />
        <InputGuesser source="contact_person" />
        <InputGuesser source="phone" />
        <InputGuesser source="email" />
    </CreateGuesser>
);

export const CommercesEdit = props => (
    <EditGuesser {...props}>
        <InputGuesser source="name" />
        <InputGuesser source="cif" />
        <InputGuesser source="contact_person" />
        <InputGuesser source="phone" />
        <InputGuesser source="email" />
    </EditGuesser>
);
