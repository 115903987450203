import React from 'react';
import { AutocompleteInput, ReferenceInput, BooleanField} from 'react-admin';
import {
    ListGuesser  ,
    FieldGuesser  ,
    CreateGuesser  ,
    InputGuesser  ,
    EditGuesser  ,
} from '@api-platform/admin';

const types = [
    { name: "house", cname: "house", value: "house" },
    { name: "orchard", cname: 'orchard', value: "orchard" },
    { name: "land", cname: 'land', value: "land" },
];

export const PropertiesList = props => (
    <div>
        <ListGuesser {...props}>
            <FieldGuesser source="name" />
            <FieldGuesser source="address" />
            <FieldGuesser source="cadastral_reference" />
            <FieldGuesser source="type" />
            <FieldGuesser source="purchaseDate" />
            <BooleanField source="onSale"/>
        </ListGuesser>
    </div>

);

export const PropertiesCreate = (props) => (
    <CreateGuesser {...props}>
        <InputGuesser source="name" />
        <InputGuesser source="address" />
        <InputGuesser source="cadastral_reference" />
        <InputGuesser source="surface" />
        <InputGuesser source="buildedSurface" />
        <InputGuesser source="purchasePrice" />
        <InputGuesser source="purchaseDate" />
        <InputGuesser source="onSale"/>
        <InputGuesser source="salePrice"/>
        <InputGuesser source="saleObservations"/>
        <AutocompleteInput
            source="type"
            choices={types}
            optionText="cname"
            optionValue="value"
        />
    </CreateGuesser>
);

export const PropertiesEdit = props => (
    <EditGuesser {...props}>
        <InputGuesser source="name" />
        <InputGuesser source="address" />
        <InputGuesser source="cadastral_reference" />
        <InputGuesser source="surface" />
        <InputGuesser source="buildedSurface" />
        <InputGuesser source="purchasePrice" />
        <InputGuesser source="purchaseDate" />
        <InputGuesser source="onSale"/>
        <InputGuesser source="salePrice"/>
        <InputGuesser source="saleObservations"/>
        <AutocompleteInput
            source="type"
            choices={types}
            optionText="cname"
            optionValue="value"
        />
        <ReferenceInput
            source="media_object"
            reference="media_objects"
            label="Image"
            filterToQuery={searchText => ({ name: searchText })}
        >
            <AutocompleteInput optionText="name" />
        </ReferenceInput>

    </EditGuesser>
);
