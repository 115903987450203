import React from 'react';

import { ReferenceField, TextField, ReferenceInput, AutocompleteInput } from 'react-admin';

import {
    ListGuesser  ,
    FieldGuesser  ,
    CreateGuesser  ,
    InputGuesser  ,
    EditGuesser  ,
} from '@api-platform/admin';

const types = [
    { name: "coche", cname: "car", value: "car" },
    { name: "moto", cname: 'motorbike', value: "motorbike" },
    { name: "bici", cname: 'bicicle', value: "bicicle" },
    { name: "ciclomotor", cname: 'ciclomotor', value: "ciclomotor" },
];

export const VehiclesList = props => (
    <div>
        <ListGuesser {...props}>
            <FieldGuesser source="brand" />
            <FieldGuesser source="model" />
            <FieldGuesser source="licensePlate" />
            <FieldGuesser source="type" />
            <FieldGuesser source="purchaseAmount" />
            <FieldGuesser source="purchaseDate" />
            <ReferenceField label="User" source="user" reference="users">
                <TextField source="username"/>
            </ReferenceField>
        </ListGuesser>
    </div>
);

export const VehiclesCreate = (props) => (
    <CreateGuesser {...props}>
        <InputGuesser source="brand" />
        <InputGuesser source="model" />
        <InputGuesser source="licensePlate" />
        <InputGuesser source="purchaseAmount" />
        <InputGuesser source="purchaseDate" />
        <AutocompleteInput
            source="type"
            choices={types}
            optionText="cname"
            optionValue="value"
        />
        <ReferenceInput
            source="user"
            reference="users"
            label="User"
            filterToQuery={searchText => ({ username: searchText })}
        >
            <AutocompleteInput optionText="username" />
        </ReferenceInput>
    </CreateGuesser>
);

export const VehiclesEdit = (props) => (
    <EditGuesser {...props}>
        <InputGuesser source="brand" />
        <InputGuesser source="model" />
        <InputGuesser source="licensePlate" />
        <InputGuesser source="purchaseAmount" />
        <InputGuesser source="purchaseDate" />
        <AutocompleteInput
            source="type"
            choices={types}
            optionText="cname"
            optionValue="value"
        />
        <ReferenceInput
            source="user"
            reference="users"
            label="User"
            filterToQuery={searchText => ({ username: searchText })}
        >
            <AutocompleteInput optionText="username" />
        </ReferenceInput>
    </EditGuesser>
);