import React from 'react';
import {ReferenceField, Filter, TextField, ReferenceInput, AutocompleteInput, FunctionField} from 'react-admin';
import {
    ListGuesser  ,
    FieldGuesser  ,
    CreateGuesser  ,
    InputGuesser  ,
    EditGuesser  ,
} from '@api-platform/admin';

const statuses = [
    { name: "created", cname: "creada", value: "created" },
    { name: "fixed", cname: "arreglado", value: "fixed" },
];

//there is an option alwaysOn for ReferenceInput
export const IncidencesFilter = (props) => (
    <Filter {...props}>
        <ReferenceInput label="Property" source="property.id" reference="properties" allowEmpty>
            <AutocompleteInput optionText="name" />
        </ReferenceInput>
    </Filter>
);

export const IncidencesList = props => (
    <div>
        <ListGuesser {...props} filters={<IncidencesFilter/>}>
            <ReferenceField label="Property" source="property" reference="properties">
                <TextField source="name"/>
            </ReferenceField>
            <FieldGuesser source="description" />
            <FieldGuesser source="status" />
            <FunctionField label="location" render={record => `${record.latitude} / ${record.longitude}`}/>
        </ListGuesser>
    </div>

);

export const IncidenceCreate = (props) => (
    <CreateGuesser {...props}>
        <ReferenceInput
            source="reporter"
            reference="users"
            label="Reporter"
            filterToQuery={searchText => ({ username: searchText })}
        >
            <AutocompleteInput optionText="username" />
        </ReferenceInput>
        <ReferenceInput
            source="property"
            reference="properties"
            label="Property"
            filterToQuery={searchText => ({ name: searchText })}
        >
            <AutocompleteInput optionText="name" />
        </ReferenceInput>
        <InputGuesser source="latitude" />
        <InputGuesser source="longitude" />
        <InputGuesser source="description" />
        <AutocompleteInput
            source="status"
            choices={statuses}
            optionText="cname"
            optionValue="value"
        />
    </CreateGuesser>
);

export const IncidenceEdit = props => (
    <EditGuesser {...props}>
        <ReferenceInput
            source="reporter"
            reference="users"
            label="Reporter"
            filterToQuery={searchText => ({ name: searchText })}
        >
            <AutocompleteInput optionText="name" />
        </ReferenceInput>
        <ReferenceInput
            source="property"
            reference="properties"
            label="Property"
            filterToQuery={searchText => ({ name: searchText })}
        >
            <AutocompleteInput optionText="name" />
        </ReferenceInput>
        <InputGuesser source="latitude" />
        <InputGuesser source="longitude" />
        <InputGuesser source="description" />
        <AutocompleteInput
            source="status"
            choices={statuses}
            optionText="cname"
            optionValue="value"
        />

    </EditGuesser>
);
