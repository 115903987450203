import React from 'react';
import {ReferenceField, Filter, TextField, ReferenceInput, AutocompleteInput} from 'react-admin';
import {
    ListGuesser  ,
    FieldGuesser  ,
    CreateGuesser  ,
    InputGuesser  ,
    EditGuesser  ,
} from '@api-platform/admin';
//there is an option alwaysOn for ReferenceInput
export const OrangeSalesFilter = (props) => (
    <Filter {...props}>
        <ReferenceInput label="Variety" source="variety" reference="varieties" allowEmpty>
            <AutocompleteInput optionText="name" />
        </ReferenceInput>
        <ReferenceInput label="Commerce" source="commerce" reference="commerces" allowEmpty>
            <AutocompleteInput optionText="name" />
        </ReferenceInput>
    </Filter>
);

export const OrangeSalesList = props => (
    <div>
        <ListGuesser {...props} filters={<OrangeSalesFilter/>}>
            <ReferenceField label="Variety" source="variety" reference="varieties">
                <TextField source="name"/>
            </ReferenceField>
            <FieldGuesser source="price_per_arroba" />
            <FieldGuesser source="collected_arrobas" />
            <FieldGuesser source="total_received" />
            <ReferenceField label="Commerce" source="commerce" reference="commerces">
                <TextField source="name"/>
            </ReferenceField>
        </ListGuesser>
    </div>

);

export const OrangeSalesCreate = (props) => (
    <CreateGuesser {...props}>
        <ReferenceInput
            source="variety"
            reference="varieties"
            label="Variety"
            filterToQuery={searchText => ({ name: searchText })}
        >
            <AutocompleteInput optionText="name" />
        </ReferenceInput>
        <InputGuesser source="price_per_arroba" />
        <InputGuesser source="collected_arrobas" />
        <InputGuesser source="total_received" />
        <ReferenceInput
            source="commerce"
            reference="commerces"
            label="Commerce"
            filterToQuery={searchText => ({ name: searchText })}
        >
            <AutocompleteInput optionText="name" />
        </ReferenceInput>
        <InputGuesser source="date_of_sale" />
        <InputGuesser source="date_of_collection" />
        <InputGuesser source="date_of_payment" />
    </CreateGuesser>
);

export const OrangeSalesEdit = props => (
    <EditGuesser {...props}>
        <ReferenceInput
            source="variety"
            reference="varieties"
            label="Variety"
            filterToQuery={searchText => ({ name: searchText })}
        >
            <AutocompleteInput optionText="name" />
        </ReferenceInput>
        <InputGuesser source="price_per_arroba" />
        <InputGuesser source="collected_arrobas" />
        <InputGuesser source="total_received" />
        <ReferenceInput
            source="commerce"
            reference="commerces"
            label="Commerce"
            filterToQuery={searchText => ({ name: searchText })}
        >
            <AutocompleteInput optionText="name" />
        </ReferenceInput>
        <InputGuesser source="date_of_sale" />
        <InputGuesser source="date_of_collection" />
        <InputGuesser source="date_of_payment" />

    </EditGuesser>
);
