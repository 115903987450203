import React from 'react';
import {ReferenceField, Filter, TextField, ReferenceInput, AutocompleteInput} from 'react-admin';
import {
    ListGuesser  ,
    FieldGuesser  ,
    CreateGuesser  ,
    InputGuesser  ,
    EditGuesser  ,
} from '@api-platform/admin';


export const OrangePricesList = props => (
    <div>
        <ListGuesser {...props}>
            <FieldGuesser source="date" />
            <FieldGuesser source="min_price" />
            <FieldGuesser source="max_price" />
            <FieldGuesser source="status" />
            <ReferenceField label="Variety" source="variety" reference="varieties">
                <TextField source="name"/>
            </ReferenceField>
        </ListGuesser>
    </div>

);

export const OrangePricesCreate = (props) => (
    <CreateGuesser {...props}>
        <ReferenceInput
            source="variety"
            reference="varieties"
            label="Variety"
            filterToQuery={searchText => ({ name: searchText })}
        >
            <AutocompleteInput optionText="name" />
        </ReferenceInput>

        <InputGuesser source="date" />
        <InputGuesser source="min_price" />
        <InputGuesser source="max_price" />
        <InputGuesser source="status" />

    </CreateGuesser>
);

export const OrangePricesEdit = props => (
    <EditGuesser {...props}>
        <ReferenceInput
            source="variety"
            reference="varieties"
            label="Variety"
            filterToQuery={searchText => ({ name: searchText })}
        >
            <AutocompleteInput optionText="name" />
        </ReferenceInput>

        <InputGuesser source="date" />
        <InputGuesser source="min_price" />
        <InputGuesser source="max_price" />
        <InputGuesser source="status" />

    </EditGuesser>
);